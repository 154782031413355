const Url = "https://admin.securefx.net";
const ClientUrl = "https://client.securefx.net";

// const IsApprove = {
//   is_app: "1",
//   AADMIN_LOGIN_ID: "3",
//   AADMIN_LOGIN_ROLE_ID: "1",
//   AADMIN_AUTH_KEY:"1d7463-26008d-8752f9",
// };
const UserInfo = {};
const IsApprove = "";
export { Url, ClientUrl, IsApprove, UserInfo };
