import * as React from "react";

function Counter(prop) {
  // Convert prop.Seconds to a number, set default to 30 if empty
  const initialCounter = prop.Seconds ? parseInt(prop.Seconds, 10) : 30;
  const [counter, setCounter] = React.useState(initialCounter);

  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      prop.setReset(false);
    }
  }, [counter]);

  React.useEffect(() => {
    // Convert prop.Seconds to a number when resetting, set default to 30 if empty
    if (prop.reset === true) {
      setCounter(prop.Seconds ? parseInt(prop.Seconds, 10) : 30);
    }
  }, [prop.reset, prop.Seconds]);

  // Calculate minutes and remaining seconds
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;

  return (
    <span>
      {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
    </span>
  );
}

export default Counter;
